import axios from "axios";
import imageCompression from "browser-image-compression";

const DEV = false;
const SERVER_URL = DEV
  ? "http://localhost:3000/dev"
  : "https://hkyag0vt54.execute-api.us-east-1.amazonaws.com/dev";

async function handleImageUpload(file) {
  try {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);
    const base64String = await imageCompression.getDataUrlFromFile(
      compressedFile
    );
    return base64String;
  } catch (error) {
    console.log(error);
  }
}

export const generate = async (uid, time, discount) => {
  try {
    const response = await axios.post(
      `${SERVER_URL}/new-spirit-animal`,
      {
        uid: uid,
        time: time,
        appliedDiscount: discount,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting result:", error);
    return null;
  }
};

export const generateRetry = async (uid, files, spiritAnimal, premium) => {
  try {
    const imageData = {};
    for (let i = 0; i < files.length; i++) {
      const base64String = await handleImageUpload(files[i]);
      imageData[`image${i + 1}`] = base64String.split(";base64,").pop();
    }

    const response = await axios.post(
      `${SERVER_URL}/retry-spirit-animal`,
      {
        uid: uid,
        ...imageData,
        spiritAnimal: spiritAnimal,
        premium: premium,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting result:", error);
    return null;
  }
};

export const createPaymentSession = async (
  isPremium,
  email,
  files,
  spiritAnimal,
  discount
) => {
  try {
    const imageData = {};
    for (let i = 0; i < files.length; i++) {
      const base64String = await handleImageUpload(files[i]);
      imageData[`image${i + 1}`] = base64String.split(";base64,").pop();
    }

    const response = await axios.post(
      `${SERVER_URL}/create-checkout-session`,
      {
        isPremium: isPremium,
        email,
        ...imageData,
        spiritAnimal: spiritAnimal,
        discount: discount,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.sessionURL;
  } catch (error) {
    console.error("Error creating payment session:", error);
    return null;
  }
};

export const createRetryPaymentSession = async (uid) => {
  try {
    const response = await axios.post(
      `${SERVER_URL}/create-retry-checkout-session`,
      {
        uid: uid,
      }
    );
    return response.data.sessionURL;
  } catch (error) {
    console.error("Error creating retry payment session:", error);
    return null;
  }
};

export const getResult = async (uid) => {
  try {
    const response = await axios.post(`${SERVER_URL}/result`, { uid: uid });
    return response.data;
  } catch (error) {
    console.error("Error getting result:", error);
    return null;
  }
};

export const fetchPresignedUrl = async (filename) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/download-image/${filename}`
    );
    return response.data.url;
  } catch (error) {
    console.error("Error getting result:", error);
    return null;
  }
};

export const fetchOriginalPresignedUrl = async (filename) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/download-original-image/${filename}`
    );
    return response.data.url;
  } catch (error) {
    console.error("Error getting result:", error);
    return null;
  }
};

export const fetchSVGPresignedUrl = async (filename) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/download-share-card/${filename}`
    );
    return response.data.url;
  } catch (error) {
    console.error("Error getting result:", error);
    return null;
  }
};
