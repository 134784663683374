import React from "react";
import styles from "./Footer.module.css";
import SocialButton from "./SocialButton";

const contactEmail = "hello@SpiritAnimal.ai";

const openTwitter = () => {
  const url = "https://twitter.com/spiritanimalai";
  window.open(url, "_blank");
};

const openInstagram = () => {
  const url = "https://www.instagram.com/spiritanimal.ai/";
  window.open(url, "_blank");
};

const openEmail = () => {
  const url = `mailto:hello@SpiritAnimal.ai`;
  window.location.href = url;
};

export const Footer = ({ bunny, result }) => {
  return (
    <div className={styles.container}>
      {result && <NotHappy />}
      <div className={styles.socialContainer}>
        <SocialButton social={"x"} onClick={openTwitter} />
        <SocialButton social={"instagram"} onClick={openInstagram} />
        <button className={`${styles.email}`} onClick={openEmail}>
          {contactEmail}
        </button>
      </div>
      {bunny && <BunnyPage />}

      <h5
        className={`subheader ${styles.agreement}`}
        style={{ marginTop: 50, marginBottom: 5 }}
      >
        By using this site you agree to the{" "}
        <a
          href="https://docs.google.com/document/d/11QdLkR1PCeDLpnVDGBaCx-seTu9V4pNsrOGIR4X23dc/edit?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </h5>
      <h5 className={`subheader ${styles.text}`} style={{ margin: 5 }}>
        We don't give refunds because we incur backend costs to generate your
        Spirit Animal
      </h5>
      <h5 className={`subheader ${styles.text}`} style={{ marginTop: 5 }}>
        ©2023 SpiritAnimal.ai
      </h5>
      <div className={styles.footer}>
        <p>v1.5.3</p>
      </div>
    </div>
  );
};

const BunnyPage = () => (
  <h4 className={`subheader ${styles.spec}`} style={{ marginBottom: 50 }}>
    Have you gotten this page multiple times?{" "}
    <a
      href="https://forms.gle/H3wta6eqDhk7A8Ff9"
      target="_blank"
      rel="noreferrer"
    >
      Contact us here
    </a>
  </h4>
);
const NotHappy = () => (
  <h4 className={`subheader ${styles.spec}`} style={{ marginBottom: 50 }}>
    Are you unhappy with your ouput?{" "}
    <a
      href={`mailto:support@SpiritAnimal.ai?subject=Spirit Animal Issue&body=We’re sorry you’re unhappy with your output! Sometimes our AI artists don’t do such a good job... Below, please describe the issue and we’ll be happy to help.%0D%0A%0D%0A**If you’re ok with us viewing your results page, please keep this URL in the email so we’ll be able to see the problem: ${window.location.href}%0D%0A%0D%0A--------------------------------------------------------------------------------------------%0D%0A%0D%0A`}
      target="_blank"
      rel="noreferrer"
    >
      Contact us here
    </a>
  </h4>
);
