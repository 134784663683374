import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import ResultScreen from "./screens/ResultScreen";
import LoadingScreen from "./screens/LoadingScreen";
import FailureScreen from "./screens/FailureScreen";
import { CreateScreen } from "./screens/CreateScreen";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/create" element={<CreateScreen />} />
        <Route path="/retry/:uid" element={<CreateScreen isRetry />} />
        <Route path="/loading/:uid" element={<LoadingScreen />} />
        <Route path="/failure/:uid" element={<FailureScreen />} />
        <Route path="/result/:uid" element={<ResultScreen />} />
      </Routes>
    </Router>
  );
};

export default App;
