import React from "react";
import PhotoUpload from "./PhotoUpload";
import styles from "./ImageSelection.module.css";

const ImageSelection = ({ setCurrentImage, premium = true }) => {
  return (
    <div className={styles.container}>
      <p className={`subheader`}>
        *Follow these guidelines for the best results
      </p>
      <ul className={styles.guidelines}>
        <li className={styles.guidelineItem}>✅ Only 1 person</li>
        <li className={styles.guidelineItem}>❌ Horizontal images</li>

        <li className={styles.guidelineItem}>✅ Full body or headshot</li>
        <li className={styles.guidelineItem}>❌ Blurry images</li>

        <li className={styles.guidelineItem}>
          ✅ Fun pose, clothing or background
        </li>
        <li className={styles.guidelineItem}>❌ Explicit content</li>
      </ul>
      <div className={styles.photoUploadContainer}>
        {premium ? (
          [1, 2, 3].map((v, i) => (
            <PhotoUpload setCurrentImage={setCurrentImage} key={i} index={i} />
          ))
        ) : (
          <PhotoUpload setCurrentImage={setCurrentImage} index={0} />
        )}
      </div>
    </div>
  );
};

export default ImageSelection;
