import React, { useEffect, useState } from "react";
import { getResult } from "../util/api";
import { useNavigate, useParams } from "react-router-dom";
import { Logo } from "../components/Logo";
import { Footer } from "../components/Footer";
import styles from "./LoadingScreen.module.css";
import TwitterFeed from "../components/TwitterFeed";
import { MBR } from "../components/MBR";

const LoadingScreen = () => {
  const [result, handleResult] = useState(null);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  let { uid } = useParams();

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const result = await getResult(uid);
        if (result === null) {
          setStatus("ERROR");
          console.error("No result.");
          return;
        }
        const { genStatus } = result;
        if (!genStatus) {
          console.log("Waiting for status...");
          setStatus("WAITING");
        } else if (genStatus.includes("SUCCESS")) {
          handleResult(result);
          setStatus("DONE");
        } else if (genStatus.includes("FAILURE")) {
          console.error("Error generating result:", genStatus);
          setStatus("ERROR");
        } else if (genStatus.includes("PROCESSING")) {
          console.log("Proccessing..");
          setStatus("WAITING");
        } else {
          console.log("Unknown genStatus:", genStatus);
          setStatus("WAITING");
        }
      } catch (error) {
        console.error("Error fetching result:", error);
        setStatus("ERROR");
      }
    };
    fetchResult();
  }, [uid]);

  useEffect(() => {
    if (status === "ERROR") {
      navigate("/failure/" + uid);
    }
  }, [navigate, status, uid]);

  useEffect(() => {
    if (result) {
      navigate("/result/" + uid, { state: { result } });
    }
  }, [navigate, result, uid]);

  return (
    <div className={"container"}>
      <Logo />
      <h1 className={"header"}>
        Our AI artists are <MBR /> hard at work
      </h1>
      <h3 className={"subheader"}>
        We’ll notify you by email when
        <MBR /> you’re Spirit Animals are done <MBR />
        (within 10 minutes)
      </h3>
      <h4 className={"subheader"}>*Make sure to check your spam folder</h4>
      <div className={styles.line}></div>
      <div className={styles.waitContentContainer}>
        <h3 className={"subheader"}>Check out where your donation went!</h3>
        <button
          className={`buttonBase ${styles.createButton} ${styles.createButtonSticky}`}
          onClick={(e) => {
            e.preventDefault();
            window.open("http://www.worldwildlife.org", "_blank");
          }}
        >
          www.worldwildlife.org
        </button>
        <h3 className={"subheader"}>Get excited for your Spirit Animals 🎉</h3>
        <TwitterFeed />
      </div>
      <Footer />
    </div>
  );
};

export default LoadingScreen;
