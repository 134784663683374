import { useEffect, useState } from "react";
import styles from "./ShareCard.module.css";

export const ShareCardDisplay = ({ id, original, generated, onImageError }) => {
  const imageHeight = 300;
  const imageWidth = imageHeight;
  const border = 5;
  const svgHeight = imageHeight + border * 2;

  const [originalImageWidth, setOriginalImageWidth] = useState(0);
  const [svgWidth, setSvgWidth] = useState(imageHeight * 2);
  const [originalLoading, setOriginalLoading] = useState(true);
  const [generatedLoading, setGeneratedLoading] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.onload = function () {
      const originalWidth = image.width;
      const originalHeight = image.height;
      const newWidth = (originalWidth / originalHeight) * imageHeight;
      setOriginalImageWidth(newWidth);
      setSvgWidth(newWidth + imageWidth + border * 3);
      setOriginalLoading(false);
    };
    image.src = original;
  }, [imageHeight, imageWidth, originalLoading, original]);

  useEffect(() => {
    const image = new Image();
    image.onload = function () {
      setGeneratedLoading(false);
    };
    image.src = generated;
  }, [generatedLoading, generated]);

  const generatedX = svgWidth - imageWidth - border;
  const originalX = generatedX - originalImageWidth - border;

  return (
    <div style={{ maxWidth: "100%", overflow: "hidden" }}>
      <svg
        width={svgWidth}
        height={svgHeight}
        id={id}
        style={{
          width: "100%",
          height: "auto",
          display: "block",
        }}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <image
          x={originalX}
          y={svgHeight / 2 - imageHeight / 2}
          height={imageHeight}
          href={original}
          clipPath="inset(0% round 15px 0px 0px 15px)"
          className={`${styles.imageFade} ${
            !originalLoading ? styles.imageLoaded : ""
          }`}
          onError={onImageError}
        />
        <image
          x={generatedX}
          y={svgHeight / 2 - imageHeight / 2}
          width={imageHeight}
          height={imageHeight}
          href={generated}
          clipPath="inset(0% round 0px 15px 15px 0px)"
          className={`${styles.imageFade} ${
            !originalLoading ? styles.imageLoaded : ""
          }`}
          onError={onImageError}
        />
      </svg>
    </div>
  );
};
