import React from "react";
import AnimalSelector from "./AnimalSelector";
import AnimalInput from "./AnimalInput";

const AnimalSelection = ({ setIsCustom, isCustom, setAnimal }) => {
  return (
    <div>
      <h1 className={"header"}>1. Choose Spirit Animal</h1>
      <AnimalSelector
        setIsCustom={setIsCustom}
        isCustom={isCustom}
        onSelect={setAnimal}
      />
      <AnimalInput
        isCustom={isCustom}
        setIsCustom={setIsCustom}
        onAnimalSubmit={setAnimal}
      />
      Need inspiration?{" "}
      <a
        href="https://www.wwf.org.za/spirit_animal/"
        target="_blank"
        rel="noreferrer"
        style={{ color: "white" }}
      >
        Take a Spirit Animal quiz
      </a>
    </div>
  );
};

export default AnimalSelection;
