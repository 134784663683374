import React from "react";
import { Tweet } from "react-twitter-widgets";
import styles from "./TwitterFeed.module.css";

const TwitterFeed = () => {
  const leftColumnTweetIds = [
    "1765998127734051115",
    "1770307486236737708",
    "1768716668467826963",
  ];
  const rightColumnTweetIds = [
    "1767096315476131934",
    "1770109165450690713",
    "1769313902196555856",
  ];

  return (
    <div className={styles.grid}>
      <div className={styles.column}>
        {leftColumnTweetIds.map((id) => (
          <div key={id} className={styles.tweetWrapper}>
            <Tweet tweetId={id} options={{ theme: "light", align: "center" }}  />
          </div>
        ))}
      </div>
      <div className={styles.column}>
        {rightColumnTweetIds.map((id) => (
          <div key={id} className={styles.tweetWrapper}>
            <Tweet tweetId={id} options={{ theme: "light", align: "center" }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TwitterFeed;
