import React, { useEffect, useState } from "react";
import {
  createPaymentSession,
  generate,
  generateRetry,
  getResult,
} from "../util/api";
import styles from "./CreateScreen.module.css";
import "../App.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Logo } from "../components/Logo";
import loading from "../images/loading.gif";
import { Footer } from "../components/Footer";
import AnimalSelection from "../components/animalSelection/AnimalSelection";
import ImageSelection from "../components/imageSelection/ImageSelection";
import PaymentInterface from "../components/PaymentInterface";
import PremiumSelection from "../components/PremiumSelection";
import FaqSection from "../components/FaqSection";

export const CreateScreen = ({ isRetry = false }) => {
  const [animal, setAnimal] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const [animalError, setAnimalError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isPremium, setIsPremium] = useState(true);
  const [isValidRetry, setIsValidRetry] = useState(false);
  const [checkRetry, setCheckRetry] = useState(true);

  const { uid } = useParams();

  const [currentImages, setCurrentImages] = useState([null, null, null]);

  const setImageAtIndex = (index, image) => {
    setCurrentImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = image;
      return newImages;
    });
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.fromResult || location.state?.fromHome) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    const fetchAndUpload = async () => {
      setPageLoading(true);
      try {
        let response = await generate(
          query.get("uid"),
          query.get("time"),
          query.get("discount")
        );
        if (response === null) {
          console.error("Error generating result, navigating to retry page");
          navigate("/retry/" + query.get("uid"));
          setPageLoading(false);
        } else {
          console.log("Generation request sent");
          navigate("/loading/" + query.get("uid"));
          setPageLoading(false);
        }
      } catch (error) {
        console.error("Error generating result:", error);
        setPageLoading(false);
      }
    };

    if (query.get("success") && query.get("uid")) {
      fetchAndUpload();
    }

    if (query.get("canceled")) {
      console.error("Payment was not completed.");
    }
  }, [navigate]);

  useEffect(() => {
    if (animal !== "") {
      setAnimalError(false);
    }
  }, [animal]);

  useEffect(() => {
    if (
      currentImages[0] &&
      (!isPremium || (currentImages[1] && currentImages[2]))
    ) {
      setImageError(false);
    }
  }, [currentImages, isPremium]);

  const validateInput = () => {
    if (isRetry && !isValidRetry) return false;
    var valid = true;
    if (animal === "" || animal === null) {
      setAnimalError(true);
      valid = false;
    }
    if (currentImages[0] === null) {
      setImageError(true);
      valid = false;
    }
    if (isPremium && currentImages.some((image) => image === null)) {
      setImageError(true);
      valid = false;
    }
    return valid;
  };

  const handlePayment = async (email) => {
    const query = new URLSearchParams(window.location.search);

    if (!validateInput()) {
      console.log(isValidRetry);
      return;
    }
    setIsLoading(true);

    if (isRetry) {
      console.log("Generating retry result");
      const filteredImages = currentImages.filter((image) => image !== null);
      const response = await generateRetry(
        uid,
        filteredImages,
        animal,
        isPremium
      );
      if (response === null) {
        console.log("Error generating result");
        setIsLoading(false);
      } else {
        navigate("/loading/" + uid);
        setIsLoading(false);
      }
      return;
    }

    const filteredImages = currentImages.filter((image) => image !== null);
    const url = await createPaymentSession(
      isPremium,
      email,
      filteredImages,
      animal,
      query.get("discount")
    );

    setIsLoading(false);
    if (url === null) {
      setPaymentError(true);
      return;
    }
    window.open(url, "_self");
  };

  useEffect(() => {
    async function fetchResult() {
      const result = await getResult(uid);
      setCheckRetry(false);
      if (
        result &&
        ((result.genStatus &&
          (result.genStatus.includes("FAILURE") ||
            result.genStatus.includes("RETRY"))) ||
          !result.generated)
      ) {
        setIsValidRetry(true);
        setIsPremium(result.premium);
      } else if (!isLoading) {
        setIsValidRetry(false);
        navigate("/create");
        return;
      }
    }
    if (isRetry && uid && checkRetry) {
      fetchResult();
    }
  });

  return (
    <div className={"container"}>
      <Logo />
      {!pageLoading && (
        <div>
          <AnimalSelection
            setIsCustom={setIsCustom}
            isCustom={isCustom}
            setAnimal={setAnimal}
          />

          <PremiumSelection
            isRetry={isRetry}
            isPremium={isPremium}
            setIsPremium={setIsPremium}
          />

          <ImageSelection
            setCurrentImage={(i, image) => setImageAtIndex(i, image)}
            premium={isPremium}
          />

          <PaymentInterface
            isLoading={isLoading}
            handlePayment={handlePayment}
            isRetry={isRetry}
            isPremium={isPremium}
          />

          <div className={styles.errorContainer}>
            {imageError ? (
              <h4 className={styles.error}>
                {isPremium
                  ? "! You have not chosen 3 photos !"
                  : "! You have not chosen a photo !"}
              </h4>
            ) : null}
            {animalError ? (
              <h4 className={styles.error}>
                ! You have not chosen an animal !
              </h4>
            ) : null}
            {paymentError ? (
              <h4 className={styles.error}>
                ! There was an error proccessing your payment. Please check your
                connection and try again !
              </h4>
            ) : null}
          </div>

          <FaqSection />
          <Footer />
        </div>
      )}
      {pageLoading && (
        <div className={`${styles.loading}`}>
          <img src={loading} alt="loading" width={100} />
        </div>
      )}
    </div>
  );
};
