export const FREE_ANIMALS = [
  { label: "Dog", emoji: "🐶" },
  { label: "Cat", emoji: "🐱" },
  { label: "Bunny", emoji: "🐰" },
  { label: "Panda", emoji: "🐼" },
  { label: "Lion", emoji: "🦁" },
  { label: "Penguin", emoji: "🐧" },
  { label: "Bear", emoji: "🐻" },
  { label: "Sloth", emoji: "🦥" },
  { label: "Fox", emoji: "🦊" },
  { label: "Koala", emoji: "🐨" },
];
