import React, { useEffect, useState } from "react";
import styles from "./AnimalSelector.module.css";
import { FREE_ANIMALS } from "../../util/constants";
import Dog from "../../images/examples/Dog.png";
import Cat from "../../images/examples/Cat.png";
import Bear from "../../images/examples/Bear.png";
import Bunny from "../../images/examples/Bunny.png";
import Fox from "../../images/examples/Fox.png";
import Lion from "../../images/examples/Lion.png";
import Koala from "../../images/examples/Koala.png";
import Panda from "../../images/examples/Panda.png";
import Penguin from "../../images/examples/Penguin.png";
import Sloth from "../../images/examples/Sloth.png";

const AnimalSelector = ({ setIsCustom, isCustom, onSelect }) => {
  const [selectedAnimal, setSelectedAnimal] = useState(null);
  const [animalImagePath, setAnimalImagePath] = useState(Dog);

  const handleSelect = async (animal) => {
    setSelectedAnimal(animal.label);
    onSelect(animal.label);
    setIsCustom(false);
    updateAnimalImagePath(animal.label);
  };

  const handleHover = (animalLabel) => {
    if (!isCustom) {
      updateAnimalImagePath(animalLabel);
    }
  };

  const updateAnimalImagePath = (animalLabel) => {
    const animalImages = {
      Dog,
      Cat,
      Bear,
      Bunny,
      Fox,
      Lion,
      Koala,
      Panda,
      Penguin,
      Sloth,
    };
    setAnimalImagePath(animalImages[animalLabel]);
  };

  useEffect(() => {
    if (isCustom) {
      setSelectedAnimal(null);
    }
  }, [isCustom]);

  return (
    <div className={styles.container}>
      <img
        src={animalImagePath}
        alt={`Selected Animal - ${selectedAnimal}`}
        className={`gradientBorder ${styles.image}`}
      />
      <div className={styles.grid}>
        {FREE_ANIMALS.map((animal, index) => (
          <button
            key={index}
            className={` ${styles.button} ${
              selectedAnimal === animal.label ? styles.selected : ""
            }`}
            onClick={() => handleSelect(animal)}
            onMouseEnter={() => handleHover(animal.label)}
            onMouseLeave={() =>
              selectedAnimal && updateAnimalImagePath(selectedAnimal)
            }
          >
            {animal.emoji}{" "}
            <span
              className={
                selectedAnimal === animal.label
                  ? styles.selectedText
                  : styles.buttonText
              }
            >
              {animal.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AnimalSelector;
