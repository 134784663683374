import styles from "./PremiumSelection.module.css";
import InfoIcon from "../images/InfoIcon.svg";

const toggleInfo = () => {
  console.log("Info");
};

const PremiumSelection = ({ isPremium, setIsPremium, isRetry = false }) => {
  const togglePremium = (newPremiumState) => {
    setIsPremium(newPremiumState);
  };

  return (
    <div>
      <h1 className={"header"}>2. Upload Photos</h1>
      <div className={styles.container}>
        <button
          className={`${styles.optionButton} ${styles.leftOption} ${
            !isPremium && styles.leftOptionButtonActive
          }`}
          onClick={() => togglePremium(false)}
          disabled={isRetry}
        >
          $4
        </button>
        <button
          className={`${styles.optionButton} ${styles.rightOption}  ${
            isPremium && styles.rightOptionButtonActive
          }`}
          onClick={() => togglePremium(true)}
          disabled={isRetry}
        >
          $6
        </button>
      </div>
      <div className={styles.descriptionContainer}>
        <p>
          {isPremium
            ? "Upload 3 photos of yourself --> receive 6 AI-generated spirit animals"
            : "Upload 1 photo of yourself --> receive 2 AI-generated spirit animals"}
        </p>
        {/* <img width={20} src={InfoIcon} alt="info" onClick={toggleInfo} /> */}
      </div>
    </div>
  );
};

export default PremiumSelection;
