import React, { useState } from "react";
import styles from "./FaqItem.module.css";

const FaqItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.faqItem}>
      <button className={styles.faqTitle} onClick={toggleOpen}>
        {title}
        <span className={styles.icon}>{isOpen ? "-" : "+"}</span>
      </button>
      {isOpen && <div className={styles.faqContent}>{children}</div>}
    </div>
  );
};

export default FaqItem;
