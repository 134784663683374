import { useState } from "react";
import { Footer } from "./Footer";
import { Logo } from "./Logo";
import loading from "../images/loading.gif";

export const Whoops = ({ image, header, subheader, onClick }) => {
  const [error, setError] = useState(false);
  return (
    <div className={"container"}>
      <Logo />
      <h1 className={"header"}>Whoops. </h1>
      <h3 className={"subheader"}>
        {header} <br></br> Please check your connection.
      </h3>
      <div className={"imageContainer"}>
        {error ? (
          <img src={loading} alt="loading" width={50} />
        ) : (
          <img
            alt="Whoops"
            className={`gradientBorder box`}
            src={image}
            onError={setError}
          />
        )}
      </div>
      <h3 className={`subheader`}>{subheader}</h3>
      <button onClick={onClick} className={`buttonBase filledButton `}>
        Refresh
      </button>
      <Footer />
    </div>
  );
};
