import { useState } from "react";
import XWhiteGray from "../images/social-icons/XWhiteGray.svg";
import XWhiteGradient from "../images/social-icons/XWhiteGradient.svg";
import XGradientGradient from "../images/social-icons/XGradientGradient.svg";

import InstaWhiteGray from "../images/social-icons/InstaWhiteGray.svg";
import InstaWhiteGradient from "../images/social-icons/InstaWhiteGradient.svg";
import InstaGradientGradient from "../images/social-icons/InstaGradientGradient.svg";

import FacebookWhiteGray from "../images/social-icons/FacebookWhiteGray.svg";
import FacebookWhiteGradient from "../images/social-icons/FacebookWhiteGradient.svg";
import FacebookGradientGradient from "../images/social-icons/FacebookGradientGradient.svg";

const SocialButton = ({ social, gradient, onClick, sx }) => {
  const [hoverState, setHoverState] = useState(false);
  const logo = {
    x: [XWhiteGray, XWhiteGradient, XGradientGradient],
    instagram: [InstaWhiteGray, InstaWhiteGradient, InstaGradientGradient],
    facebook: [
      FacebookWhiteGray,
      FacebookWhiteGradient,
      FacebookGradientGradient,
    ],
  };
  const defaultMode = gradient ? 1 : 0;

  return (
    <img
      src={
        hoverState ? logo[social][defaultMode + 1] : logo[social][defaultMode]
      }
      alt=""
      onClick={onClick}
      style={{ cursor: "pointer" }}
      width={sx && sx.width ? sx.width : 50}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    />
  );
};

export default SocialButton;
