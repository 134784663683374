import { Footer } from "../components/Footer";
import HeroShot from "../images/home/HeroShot_SideBySide.png";
import Tutorial from "../images/home/Tutorial.png";
import MobileTutorial from "../images/home/MobileTutorial.png";
import styles from "./HomeScreen.module.css";
import ShareCard0 from "../images/home/examples/ShareCard-0.png";
import ShareCard1 from "../images/home/examples/ShareCard-1.png";
import ShareCard2 from "../images/home/examples/ShareCard-2.png";
import ShareCard3 from "../images/home/examples/ShareCard-3.png";
import ShareCard4 from "../images/home/examples/ShareCard-4.png";
import ShareCard5 from "../images/home/examples/ShareCard-5.png";
import { useNavigate } from "react-router-dom";
import { Logo } from "../components/Logo";
import TwitterFeed from "../components/TwitterFeed";
import FaqSection from "../components/FaqSection";
import { MBR } from "../components/MBR";
import { Helmet } from "react-helmet";

const shareCards = [
  ShareCard0,
  ShareCard1,
  ShareCard2,
  ShareCard3,
  ShareCard4,
  ShareCard5,
];

const HomeScreen = () => {
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    navigate("/create", { state: { fromHome: true } });
  };

  const isMobile = window.innerWidth < 768;
  const tutorialImage = isMobile ? MobileTutorial : Tutorial;

  return (
    <div className={"container"}>
      <Helmet>
        <title>Create your own AI-generated spirit animals</title>
        <meta
          name="description"
          content="Create your own AI-generated spirit animals"
        />
      </Helmet>
      <Logo sx={{ position: "fixed" }} />
      <button
        className={`buttonBase ${styles.createButton} ${styles.createButtonSticky}`}
        onClick={handleSubmit}
      >
        Create Spirit Animal
      </button>
      <br />
      <br />
      <br />

      <h1 className={styles.header}>
        Visualize <MBR />
        your inner <MBR />
        Spirit Animal
      </h1>
      <div className={styles.imageContainer}>
        <img src={HeroShot} alt={`Hero Shot`} className={styles.hero} />
        <img src={tutorialImage} alt={`Tutorial`} className={styles.tutorial} />
      </div>
      <div>
        <button
          className={`buttonBase ${styles.createButton}`}
          onClick={handleSubmit}
        >
          Create Spirit Animal
        </button>
        <p className={`greenGradientText`}>
          50% of all profits will be donated to the World Wildlife Fund
        </p>
      </div>
      <div className={styles.celebrityContainer}>
        <h1 className={styles.header}>Celebrity Cameos</h1>
        <div className={styles.celebrityGrid}>
          {shareCards.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`ShareCard ${index}`}
              className={styles.shareCard}
            />
          ))}
        </div>
      </div>

      <FaqSection />
      <div className={styles.twitterSection}>
        <h1 className={styles.header}>
          Spirit Animals <MBR />
          in the Wild
        </h1>
        <TwitterFeed />
      </div>

      <Footer />
    </div>
  );
};

export default HomeScreen;
