import React, { useEffect, useState, useRef } from "react";
import { SyncLoader } from "react-spinners";
import styles from "./PaymentInterface.module.css";
import StripeWarning from "../images/StripeSentence.svg";
import { MBR, DBR } from "./MBR";

const PaymentInterface = ({ isLoading, handlePayment, isRetry, isPremium }) => {
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [shake, setShake] = useState(false);
  const emailInputRef = useRef();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!emailIsValid) {
      setShake(true);
      setTimeout(() => setShake(false), 820); // Reset after the same duration as your animation
    } else {
      handlePayment(email);
    }
  };

  const handleBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    setEmailIsValid(isRetry || emailRegex.test(email));
  };

  const handleEmail = (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    setEmailIsValid(isRetry || emailRegex.test(email));
    emailInputRef.current.blur();
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    setEmailIsValid(isRetry || emailRegex.test(email));
  }, [email, isRetry]);

  return (
    <div className={styles.paymentContainer}>
      <h1 className={"header"}>3. Submit</h1>
      <p className={styles.subheader}>
        After inputting your email and paying,
        <MBR /> we’ll notify you <DBR />
        when your Spirit Animals <MBR />
        are done (within 10 minutes)
      </p>
      <p className={`${styles.paymentFooter} greenGradientText`}>
        50% of all profits will be donated <MBR />
        to the World Wildlife Fund
      </p>
      {!isLoading && (
        <div className={styles.buttonContainer}>
          {!isRetry && (
            <form onSubmit={handleEmail}>
              <input
                ref={emailInputRef}
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
                onBlur={handleBlur}
                className={`${styles.emailInput} ${
                  emailIsValid ? styles.isValid : null
                } ${shake ? styles.shake : ""}`}
              />
            </form>
          )}

          <button
            className={`buttonBase  ${styles.paymentButton} ${
              emailIsValid && styles.paymentReady
            }`}
            onClick={handleSubmit}
            // disabled={isLoading || !emailIsValid}
          >
            Create spirit animal for{" "}
            {isRetry ? "free" : isPremium ? "$6" : "$4"}
          </button>
          <img
            src={StripeWarning}
            alt="Safe & Secure Powered by stripe"
            width={250}
          />
        </div>
      )}
      {isLoading && (
        <SyncLoader
          cssOverride={{ marginTop: "40px" }}
          color="#AD33BA"
          loading={isLoading}
        />
      )}
    </div>
  );
};

export default PaymentInterface;
