import { openDB } from "idb";

export const dbPromise = openDB("myDatabase", 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains("images")) {
      db.createObjectStore("images", { keyPath: "id" });
    }
  },
});

export async function saveImageToDB(imageBlob, id) {
  const arrayBuffer = await imageBlob.arrayBuffer();
  const mimeType = imageBlob.type;

  try {
    const db = await dbPromise;
    const tx = db.transaction("images", "readwrite");
    await tx.store.put({ id, arrayBuffer, mimeType });
    await tx.done;
    console.log("Saved to DB successfully");
  } catch (error) {
    console.error("Error saving to DB:", error);
    throw error; // Rethrow to catch it outside if necessary
  }
}

export async function getImageFromDB(id) {
  const db = await dbPromise;
  const tx = db.transaction("images", "readonly");
  const imageRecord = await tx.store.get(id);
  await tx.done;

  if (imageRecord?.arrayBuffer) {
    const blob = new Blob([imageRecord.arrayBuffer], {
      type: imageRecord.mimeType,
    });
    return blob;
  }

  return null;
}
