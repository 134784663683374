import FAQGrid from "../images/home/FAQGrid.png";
import FaqItem from "../components/FaqItem";
import styles from "./FaqSection.module.css";

const FaqSection = () => {
  return (
    <div className={styles.faqSection}>
      <h1 className={styles.header}>FAQs</h1>
      <FaqItem title="What can I do with my AI-generated Spirit Animals?">
        <div>
          <div>
            <p>Popular use-cases: </p>
            <ul>
              <li>Post/story on Instagram, Twitter, etc. </li>
              <li>
                Send it to your friend group chat and see who has the best
                Spirit Animal
              </li>
            </ul>
          </div>
          <div>
            <p>Creative use-cases (by uploading photos of other people):</p>
            <ul>
              <li>Cheer up a sick friend in a hospital</li>
              <li>Frame it for your nephew’s bday </li>
              <li>
                Make a point that your best friend really does look like an
                oversized chihuahua
              </li>
            </ul>
          </div>
        </div>
      </FaqItem>
      <FaqItem title="How much does it cost & what do I get?">
        <div>
          <img width={"100%"} src={FAQGrid} alt={`Hero Shot`} />
        </div>
      </FaqItem>
      <FaqItem title="Are my photos & payment secure?">
        <p style={{ paddingLeft: "0px !important" }}>
          Yes, we do the following to ensure your privacy and safety:
        </p>
        <ol>
          <li>
            Store your photos on Amazon’s highly secure servers & use them only
            for making your AI-generated Spirit Animals
          </li>
          <br />
          <li>
            Use Stripe payment system and do not store your credit card
            information
          </li>
        </ol>
      </FaqItem>
    </div>
  );
};

export default FaqSection;
