import React from "react";
import styles from "./Logo.module.css";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.svg";

export const Logo = ({ sx }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/")}
      className={styles.logo}
      style={{ ...sx }}
    >
      <img src={logo} alt="spirit-bunny" width={50} />
    </div>
  );
};
