import React, { useEffect, useState } from "react";
import styles from "./AnimalInput.module.css";

const AnimalInput = ({ isCustom, setIsCustom, onAnimalSubmit }) => {
  const [animal, setAnimal] = useState("");
  const [hasInput, setHasInput] = useState(false);
  const [error, setError] = useState(false);

  const validateInput = () => {
    const wordCount = animal.trim().split(/\s+/).length;
    if (wordCount > 2) {
      setHasInput(false);
      onAnimalSubmit("");
      setError(true);
    } else {
      setError(false);
      setHasInput(animal.trim().length > 0);
      onAnimalSubmit(animal.trim());
      setIsCustom(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validateInput();
    event.target.querySelector("input").blur();
  };
  const handleBlur = () => {
    validateInput();
  };

  const handleChange = (e) => {
    setAnimal(e.target.value);
    if (e.target.value.trim().length === 0) {
      setHasInput(false);
    }
  };

  useEffect(() => {
    if (!isCustom) {
      setHasInput(false);
      setAnimal("");
    }
  }, [isCustom]);

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="text"
          placeholder={"Type your own here..."}
          value={animal}
          onChange={handleChange}
          required
          onBlur={handleBlur}
          className={`gradientBorder ${styles.input} ${
            hasInput ? styles.inputValid : ""
          }`}
          onClick={() => setHasInput(false)}
        />
        <p className={styles.examples}>
          examples: orangutan, dragon, goldfish, eagle...
        </p>
      </form>
      {error && (
        <p className={styles.error}>
          Maximum of 2 words, please. (e.g. "Gummy Bear")
        </p>
      )}
    </div>
  );
};

export default AnimalInput;
