import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Logo } from "../components/Logo";
import errroImage from "../images/error.png";
import { Footer } from "../components/Footer";
import styles from "./FailureScreen.module.css";

const FailureScreen = () => {
  const navigate = useNavigate();

  let { uid } = useParams();

  return (
    <div className={"container"}>
      <Logo />
      <h1 className={"header"}>Whoops. </h1>
      <h3 className={"subheader"}>
        Bunny is confused. <br></br> Sorry about that.
      </h3>
      <div className={"imageContainer"}>
        <img
          src={errroImage}
          alt="generated"
          className={`gradientBorder box`}
        />
      </div>
      <h3 className={`subheader ${styles.onUs}`}>
        You will not be charged again. It’s on us.
      </h3>
      <button
        onClick={() => {
          navigate("/retry/" + uid);
        }}
        className={`buttonBase filledButton `}
      >
        Try Again
      </button>

      <Footer bunny={true} />
    </div>
  );
};

export default FailureScreen;
