import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Logo } from "../components/Logo";
import styles from "./ResultScreen.module.css";
import {
  fetchOriginalPresignedUrl,
  fetchPresignedUrl,
  fetchSVGPresignedUrl,
  getResult,
} from "../util/api";
import { ShareCardDisplay } from "../components/ShareCard";
import { Footer } from "../components/Footer";
import { SyncLoader } from "react-spinners";
import loadingGif from "../images/loading.gif";
import errorImage from "../images/bad_connection.png";
import { Whoops } from "../components/Whoops";
import SocialButton from "../components/SocialButton";
import { MBR } from "../components/MBR";

const makeTweet = () => {
  const url = "https://twitter.com/intent/post?text=@SpiritAnimalAI";
  window.open(url, "_blank");
};

const makeFacebookPost = () => {
  const url =
    "https://www.facebook.com/sharer/sharer.php?u=https://spiritanimal.ai";
  window.open(url, "_blank");
};

const ResultScreen = () => {
  const navigate = useNavigate();
  let { state } = useLocation();

  const [copied, setCopied] = useState(false);

  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);

  const [downloading, setDownloading] = useState([false, false, false]);

  const [isMobile, setIsMobile] = useState(false);

  const [originalImages, setOriginalImages] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [images, setImages] = useState([]);

  const [svgUrls, setSvgUrls] = useState([]);
  const [imageError, setImageError] = useState(false);

  const [resultState, setResultState] = useState(null);

  const handleImageError = () => {
    setImageError(true);
  };

  let { uid } = useParams();

  const parseImageUrl = (imageUrl) => imageUrl.split("/").pop();

  const handleDownloading = (index, condition) => {
    setDownloading((downloading) =>
      downloading.map((item, idx) => (idx === index ? condition : item))
    );
  };

  useEffect(() => {
    const fetchPreSignedUrls = async (generatedUrls, originalUrls) => {
      setLoading(true);

      const getCachedUrl = (key) => {
        const item = localStorage.getItem(key);
        if (item) {
          const { url, timestamp } = JSON.parse(item);
          const age = Date.now() - timestamp;
          if (age < 60 * 60 * 1000) {
            return url;
          }
        }
        return null;
      };

      const cacheUrl = (key, url) => {
        const item = {
          url,
          timestamp: Date.now(),
        };
        localStorage.setItem(key, JSON.stringify(item));
      };

      const fetchAndCacheUrl = async (imageUrl, fetchFunction, id) => {
        const filename = parseImageUrl(imageUrl);
        const cacheKey = `cached_url_${id}_${filename}`;
        let cachedUrl = getCachedUrl(cacheKey);

        if (!cachedUrl) {
          try {
            cachedUrl = await fetchFunction(filename);
            if (cachedUrl) {
              cacheUrl(cacheKey, cachedUrl);
            } else {
              throw new Error("Fetched URL is null");
            }
          } catch (error) {
            console.error("Error fetching presigned URL:", error);
            throw error;
          }
        }
        return cachedUrl;
      };

      try {
        const urls = await Promise.all(
          generatedUrls.map((imageUrl) =>
            fetchAndCacheUrl(imageUrl, fetchPresignedUrl, "generated")
          )
        );
        setGeneratedImages(urls);

        const urlsOG = await Promise.all(
          originalUrls.map((imageUrl) =>
            fetchAndCacheUrl(imageUrl, fetchOriginalPresignedUrl, "images")
          )
        );

        setOriginalImages(urlsOG);

        const shareCardUrls = generatedUrls.map(
          (_, index) =>
            `https://spirit-animal-image-store.s3.amazonaws.com/share-card/${uid}_${index}.png`
        );

        const svgUrls = await Promise.all(
          shareCardUrls.map((url) =>
            fetchAndCacheUrl(url, fetchSVGPresignedUrl, "share-card")
          )
        );
        setSvgUrls(svgUrls);

        if (shareCardUrls.length === 0) {
          console.error("No share card URLs");
          return;
        }
        setLoading(false);
      } catch (error) {
        console.error("Error during URL fetch and cache:", error);
      }
    };

    const checkMobileDevice = () => {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    };

    const loadImages = (result) => {
      setImages(result.generatedImageUrls);
      fetchPreSignedUrls(result.generatedImageUrls, result.originalImageUrls);
    };

    const loadData = async () => {
      const result = await getResult(uid);
      if (result === null) {
        navigate("/failure/" + uid);
        return;
      }
      const { genStatus } = result;
      if (!genStatus.includes("SUCCESS")) {
        navigate("/loading/" + uid);
        return;
      }
      setResultState(result);
      checkMobileDevice();
      loadImages(result);
    };

    const loadFromState = (state) => {
      checkMobileDevice();
      loadImages(state.result);
    };

    if (!state && !resultState) {
      loadData();
    } else if (state) {
      loadFromState(state);
    }
    setInitLoading(false);
  }, [navigate, resultState, state, uid]);

  const handleDownloadImage = async (index) => {
    if (isMobile) {
      const newWindow = window.open("", "_blank");
      try {
        const url = generatedImages[index];
        newWindow.location.href = url;
      } catch (error) {
        console.error("Error fetching presigned URL:", error);
        newWindow.close();
      }
    } else {
      handleDownloading(index, true);
      await downloadImage(images[index], `SpiritAnimalAI-${index}.png`);
      handleDownloading(index, false);
    }
  };

  const handleDownloadShareCard = async (index) => {
    if (isMobile) {
      const newWindow = window.open("", "_blank");
      try {
        const url = svgUrls[index];
        newWindow.location.href = url;
      } catch (error) {
        console.error("Error fetching presigned URL:", error);
        newWindow.close();
      }
    } else {
      handleDownloading(index, true);
      await downloadImage(
        svgUrls[index],
        `SpiritAnimalAI-${index}-ShareCard.png`
      );
      handleDownloading(index, false);
    }
  };

  const downloadImage = async (url, filename) => {
    try {
      console.log("Downloading image:", url);
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  const renderShareCards = () => {
    return generatedImages.map((url, index) => (
      <div key={index} className={styles.imageWrapper}>
        <ShareCardDisplay
          id={`display-share-card-${index}`}
          original={originalImages[Math.floor(index / 2)]}
          generated={url}
          onImageError={handleImageError}
        />
        <div className={styles.actionButtonContainer}>
          <div className={styles.horizontalButtonContainer}>
            <div className={styles.downloadButtonContainer}>
              {!downloading[index] && (
                <button
                  onClick={() => handleDownloadShareCard(index)}
                  className={`buttonBase ${styles.downloadButton}`}
                  disabled={loading[index]}
                >
                  <span className={styles.buttonText}>
                    Download side-by-side
                  </span>
                </button>
              )}
              {downloading[index] && (
                <SyncLoader
                  color="#AD33BA"
                  loading={true}
                  style={{ marginTop: "0px" }}
                />
              )}
            </div>
            {/* <div className={styles.socialButtonContainer}> */}
            <SocialButton sx={{ width: 45 }} social={"x"} onClick={makeTweet} />
            <SocialButton
              sx={{ width: 45 }}
              social={"facebook"}
              onClick={makeFacebookPost}
            />
            {/* </div> */}
          </div>
          <div className={styles.downloadText}>
            {!downloading[index] && (
              <p className={`subheader ${styles.nowrap}`}>
                Or{" "}
                <button
                  className={styles.textButton}
                  onClick={() => handleDownloadImage(index)}
                >
                  download
                </button>{" "}
                AI image only
              </p>
            )}
          </div>
        </div>
      </div>
    ));
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        console.log("Page URL copied to clipboard");
        setCopied(true);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        // Handle the error (show error message to user, etc.)
      });
  };

  if (imageError) {
    return (
      <Whoops
        image={errorImage}
        header={"Your internet isn't stable."}
        subheader={"Refresh the page and try again."}
        onClick={() => {
          window.location.reload();
        }}
      />
    );
  }

  return (
    <div className={"container"}>
      <Logo />
      {!loading && !initLoading ? (
        <>
          <h1 className={`header`}>
            Your Spirit Animals <MBR /> Have Arrived!
          </h1>
          <h3 className={`subheader`}>
            Download below and <MBR />
            share with your friends
          </h3>
          <div className={"imageContainer"}>
            <div>{renderShareCards()} </div>
          </div>
          <div className={styles.line}></div>

          <h4
            className={"subheader"}
            style={{
              marginTop: 100,
              marginBottom: 0,
            }}
          >
            Keep your spirit animal page forever. <br />
            You can share it with friends!
          </h4>
          <button
            onClick={handleCopy}
            className={`buttonBase ${styles.linkButton}`}
          >
            <span className={styles.buttonText}>
              {copied ? "Copied" : "Copy Link"}
            </span>
          </button>
          <h4
            className={"subheader"}
            style={{ marginTop: 50, marginBottom: 0 }}
          >
            Want to generate another Spirit Animal?
          </h4>
          <button
            className={`buttonBase ${styles.discountButton}`}
            onClick={() => {
              navigate("/create", { state: { fromResult: true } });
            }}
          >
            <span className={styles.buttonText}>Create More</span>
          </button>
        </>
      ) : (
        <div className={styles.loadingContainer}>
          <h2 className={`header`}>
            Loading your Spirit Animals... <br />
          </h2>
          <img src={loadingGif} alt="loading" style={{ maxWidth: 50 }} />
        </div>
      )}
      <Footer result={true} />
    </div>
  );
};

export default ResultScreen;
