import React, { useState, useRef, useEffect } from "react";
import styles from "./PhotoUpload.module.css";
import uploadLogo from "../../images/upload.svg";
import uploadLogoGradient from "../../images/upload-gradient.svg";

const PhotoUpload = ({ setCurrentImage, index }) => {
  const [, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [hover, setHover] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const checkMobileDevice = () => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
  };

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = getFileFromEvent(event);
    processFile(file);
  };

  const processFile = (file) => {
    if (file) {
      if (file.type.match("image/png") || file.type.match("image/jpeg")) {
        const tempImg = new Image();
        tempImg.onload = () => {
          const aspectRatio = tempImg.width / tempImg.height;
          if (aspectRatio <= 1) {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
            setCurrentImage(index, file);
          } else {
            alert("Please select another image that is not horizontal.");
          }
        };
        tempImg.src = URL.createObjectURL(file);
      } else {
        alert("Please select a valid image file (PNG or JPEG).");
      }
    }
  };

  const getFileFromEvent = (event) => {
    if (event.dataTransfer) {
      return event.dataTransfer.files[0]; 
    } else {
      return event.target.files[0]; 
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = getFileFromEvent(event);
    processFile(file);
  };

  useEffect(() => {
    checkMobileDevice();
  }, []);

  const triggerFileSelectPopup = () => fileInputRef.current.click();

  return (
    <div>
      <div
        className={`${styles.container} ${
          previewUrl ? styles.containerPreview : ""
        }`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <label className={styles.upload}>
          {!previewUrl && (
            <p className={styles.buttonText}>
              <img
                src={hover ? uploadLogoGradient : uploadLogo}
                alt="Upload"
                className={styles.uploadLogo}
              />
              <br />
              {isMobile ? (
                <>
                  tap to <br />
                  upload
                </>
              ) : (
                <>
                  drag & drop <br />
                  or <br />
                  <span style={{ textDecoration: "underline" }}>browse</span>
                </>
              )}
            </p>
          )}
          <input
            type="file"
            onChange={handleFileChange}
            className={styles.input}
            style={{ display: "none" }}
            accept="image/png, image/jpeg"
            ref={fileInputRef}
          />
        </label>
        {previewUrl && (
          <img
            src={previewUrl}
            alt="Preview"
            className={styles.preview}
            onClick={triggerFileSelectPopup}
          />
        )}
      </div>
    </div>
  );
};

export default PhotoUpload;
